/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

// const $ = require("jquery")

export const onRouteUpdate = () => {
    console.log('onRouteUpdate is here');

    const domain = document.domain;
    const links = Array.from(document.getElementsByTagName("a"));

    links.forEach(link => {
    const href = link.getAttribute('href');
    if (href && href.includes('//') && !href.includes(domain)) {
        link.setAttribute('target', '_blank');
        link.setAttribute('rel', 'noopener noreferrer');
    }
    });

    // IntersectionObserver
    const globalMenu = document.querySelectorAll(".mt-header");
    const leafHero = document.querySelectorAll(".leaf-hero");
    const globalFooter = document.querySelectorAll(".mt-global-footer");

    const option = {
        rootMargin: '0px',
        threshold: 0
    };
    const optionB = {
        rootMargin: '0px',
        threshold: 0.8
    };

    // Initialize
    let observerA = new IntersectionObserver(actionA, option);
    // let observerB = new IntersectionObserver(actionB, optionB);

    if (leafHero.length > 0) { // activate only leaf-page
        observerA.observe(leafHero[0]);
        // observerB.observe(globalFooter[0]);
    }

    // Intersection Method
    function actionA(entries, object) {
        if (!globalMenu.length) return;

        const adhesionClassList = globalMenu[0].classList;

        entries.forEach(function (entry, i) {
            const isIntersecting = entry.isIntersecting;
            if (isIntersecting === false && !adhesionClassList.contains('adhesion')) {
                adhesionClassList.add('adhesion');
            } else if (isIntersecting === true && adhesionClassList.contains('adhesion')) {
                adhesionClassList.remove('adhesion');
            }
        });
    }

    // Intersection Method
    function actionB(entries) {
        const targetAd = document.querySelectorAll('.member-subscribe-B, .member-subscribe-C');

        entries.forEach(function (entry) {
            if (entry.isIntersecting) {
                targetAd.forEach(function (targetModal, i) {
                    if (!targetModal.classList.contains('active')) {
                        targetModal.classList.add('active');
                        document.querySelector('body').style.overflow = 'hidden';
                    }
                });
            }
        });

        if (targetAd.length > 0) {
            const closeBoxes = document.querySelectorAll("#ad-banner.member-subscribe-C, #ad-banner.member-subscribe-D");
            closeBoxes.forEach(function (closeBox, i) {
                closeBox.addEventListener('click', function () {
                    targetAd.forEach(function (targetModal) {
                        if (targetModal.classList.contains('active')) {
                            targetModal.classList.add('inactive');
                            document.querySelector('body').style.overflow = 'initial';
                        } else {
                            targetModal.classList.remove('active');
                        }
                    });
                });
            });
        }
    }

    // scroll
    let lastScrollTop = 0;

    function handleScroll() {
        const st = window.pageYOffset || document.documentElement.scrollTop;

        if (st > lastScrollTop && globalMenu[0].classList.contains('appeared')) {
            globalMenu[0].classList.remove('appeared');
        } else if (st < lastScrollTop && !globalMenu[0].classList.contains('appeared')) {
            globalMenu[0].classList.add('appeared');
        }

        lastScrollTop = Math.max(st, 0);
    }

    if (globalMenu.length) {
        window.addEventListener("scroll", handleScroll, false);
    }

}
module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-starter-default","short_name":"starter","start_url":"/mt-build","background_color":"#f5f5f5","theme_color":"#f5f5f5","display":"minimal-ui","icon":"src/images/modernTimes-icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"d5a24365ca3f225c2dbcf5f3398a35ca"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-PTMLZKB","includeInDevelopment":false,"defaultDataLayer":null,"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com","selfHostedPath":"gtm.js"},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-source-wordpress/gatsby-browser.js'),
      options: {"plugins":[],"url":"https://stg.moderntimes.tv/cms/wordpress/graphql","html":{"useGatsbyImage":false,"createStaticFiles":true,"imageMaxWidth":1024,"fallbackImageMaxWidth":800,"imageQuality":80,"generateWebpImages":true,"generateAvifImages":false,"placeholderType":"dominantColor"},"production":{"hardCacheMediaFiles":true,"allow404Images":false,"allow401Images":false},"develop":{"nodeUpdateInterval":1000000,"hardCacheMediaFiles":true,"hardCacheData":false},"schema":{"perPage":100,"requestConcurrency":15,"previewRequestConcurrency":5,"queryDepth":15,"circularQueryLimit":5,"typePrefix":"Wp","timeout":30000},"debug":{"graphql":{"writeQueriesToDisk":true,"showQueryVarsOnError":false,"showQueryOnError":false,"copyQueryOnError":false,"panicOnError":false,"onlyReportCriticalErrors":true,"copyNodeSourcingQueryAndExit":false,"printIntrospectionDiff":false},"preview":false,"timeBuildSteps":false,"disableCompatibilityCheck":false,"throwRefetchErrors":false},"verbose":true,"catchLinks":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://www.moderntimes.tv"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
